import { CognitoUserPool } from "amazon-cognito-identity-js";
import UserAuth from "./authentication/UserAuth";

class N7SDK {
    constructor(){
        const cpUserPoolData = {
            UserPoolId: "us-east-1_F2CCyhHpK",
            ClientId: "1r8oirmfs0oq30rps2rd719dbi"
        }
        const cpUserPool = new CognitoUserPool(cpUserPoolData);

        this.CpUserAuth = new UserAuth(cpUserPool);
    }
}

export default N7SDK;